@import "../../sass//main.scss";

.homePage {
  &__main-content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    max-width: 110rem;

    @media screen and (max-device-width: 480px) {
      margin-top: 13rem;
    }
  }

  &__card-content {
    max-width: 75%;
    text-align: left;
    margin: auto;

    &-title {
      font-size: 1.6rem;
    }

    @media screen and (max-device-width: 480px) {
      max-width: 100%;
    }
  }
}
