.navbar {
  flex-direction: row;
  width: 100%;
  height: inherit;
  max-width: 1023px;
  font-size: 1.4rem;
  flex-direction: row;

  align-items: center;
  justify-content: space-evenly;

  a {
    text-decoration: none;
    width: fit-content;
  }

  &__display-contents {
    @media screen and (max-device-width: 480px) {
      display: contents;
    }
  }

  &__dropdown {
    text-align: center;
  }

  &__navlink {
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    font-weight: 500;
    text-transform: uppercase;
    align-items: center;
    border-bottom: 0.2rem solid transparent;
    padding-bottom: 0.4rem;
    position: relative;
    width: fit-content;
    top: 0.3rem;
    opacity: 0.9;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (max-device-width: 480px) {
      padding: 0.25rem;
      margin: 0rem 0.75rem;
    }
    &:hover {
      cursor: pointer;
      color: #ff9b00;
      padding-bottom: 0.4rem;
    }
  }

  &__navlink-admin {
    color: rgb(0, 255, 21);
    font-size: 1.4rem;
    margin: 0rem 0.5rem;
    padding: 0.1rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    font-weight: 500;
    text-transform: uppercase;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__active-navlink {
    color: #ff9b00 !important;
    padding-bottom: 0.4rem;
    position: relative;
    top: 0.3rem;
    opacity: 0.9;
  }
  &__active-navlink-section {
    font-weight: 900 !important;
  }

  &__sign-out-icon {
    background-color: #ff9b00;
    border-radius: 0.5rem;
    width: 2.5rem;
    height: auto;
    margin: 0.2rem;
    margin-right: 2rem;
  }

  &__navlink-sign-out {
    border-bottom: 0.2rem solid transparent;
    padding-right: 12px;
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    p {
      position: relative;
      top: 0.6rem;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: #ff9b00;
      color: white;
      border-radius: 0.5rem;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }
}
