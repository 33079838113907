@import "../../sass//main.scss";

.horizontal-bar-full {
  color: $color-primary-background;
  background-color: white;
  font-size: 1.8rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 9999;

  b {
    color: orange;
  }
  &__fixed-top {
    background-color: #1f1f1f;
    position: fixed;
    top: 0rem;
    height: 6rem;
    color: white;
    flex-direction: row;
    justify-content: flex-start;

    a {
      color: white;
      font-family: roboto;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__fixed-top-row-two {
    position: fixed;
    top: 6rem;
    height: 4rem;
    overflow-x: auto;
    justify-content: flex-start;
    overflow-y: hidden;
  }

  &__navlink {
    width: 30%;

    @media screen and (max-device-width: 480px) {
      line-height: 1;
    }
  }
}
