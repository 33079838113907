.navbar-sign-out {
  background-color: #ff9b00;
  border-radius: 3rem;
  width: 2rem;
  height: auto;
  border-bottom: 0.2rem solid transparent;
  margin: 1rem;
  @media screen and (max-device-width: 480px) {
    width: 3rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #ff9b00;
    border-radius: 0.5rem;
  }
}
