@import "../../sass//main.scss";

.footer {
  background-color: $color-primary-background;
  width: 100%;
  min-height: 100px;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  margin-top: 5rem; 

  &__wrapper {
    color: $color-primary;
    font-size: 1.4rem;
    margin: 2px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
