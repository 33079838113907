.sign-in-options {
  background-color: ghostwhite;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  margin: auto;
  justify-content: center;
  box-shadow: 0 0 2px black;
  margin-top: 4rem;
  @media screen and (max-device-width: 480px) {
    font-size: 1.3rem;
    margin: 5rem 0rem;
  }

  &__back-button {
    width: 50%;
    border: 1px solid #ff8914;
    border-radius: 1px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vw;
    color: #ff8914;
    cursor: pointer;
    text-transform: uppercase;
    margin: 2.5vw 25%;
    margin-bottom: 3.5vw;

    &:hover {
      opacity: 0.8;
    }
  }

  &__button-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__individual-button-wrap {
    border: 4px solid black;
  }
  &__title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  li {
    text-align: left;
    font-weight: normal;
    list-style-type: none;
    margin: 1rem 0rem;
  }

  ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .li-note {
    text-align: center;
    border: 1px solid black;
    margin: 2rem 0rem;
  }

  h3 {
    align-content: center;
    display: flex;
    justify-content: center;
    margin-bottom: 2vw;
  }
}
