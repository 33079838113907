.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 3px 0px black;
  border: 2px solid black;
  position: relative;
  margin: 5rem 0rem;
  padding: 3rem 10rem;
  font-size: 1.4rem;

  @media screen and (max-device-width: 480px) {
    padding: 1rem;
    font-size: 1.4rem;
  }

  .title {
    text-align: center;
    padding: 1vw;
    color: orange;
    background-color: linen;
    border: 2px solid black;
  }

  &__fine-print {
    font-style: italic;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    padding: 0vw 0vw 1vw 0vw;
  }
  h3 {
    text-align: center;
    font-weight: normal;
  }
  .description {
    text-align: center;
    font-variant: all-small-caps;
    font-weight: bold;
    font-size: 2rem;
  }
}
