@import "../sass//main.scss";

.jplogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -5rem;
  width: auto;
  margin: auto;

  &__login-page {
    @media screen and (max-device-width: 480px) {
      margin-top: 4rem; 
    }
  }

  &__title {
    font-weight: 600;
    color: $color-primary;
    font-size: 4.2rem;
  }

  &__text {
    color: black;
    font-weight: 400;
    font-size: 5rem;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
}
