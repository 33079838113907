@import "abstracts/variables";
@import "sizes/sizes";

.lt {
  text-decoration: line-through;
}
.uc {
  text-transform: uppercase;
}
.b {
  font-weight: bolder;
}
.i {
  font-style: italic;
}

.col-b {
  @media screen and (max-device-width: 767px) {
  }
}
