@import "../../sass//main.scss";

.sisu {
  width: auto;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  position: relative;
  top: -2rem;

  &__account-create {
    display: inline-flex;

    button {
      cursor: pointer;
      border: 1px solid darkgray;
      border-radius: 0px;
      width: 7rem;
      padding: 0.9rem 0rem;
      background-color: white;
      color: $color-primary;
      font-weight: 600;
      font-size: 16px;
      font-family: Roboto;
    }
    p {
      align-self: center;
      padding-right: 3rem;
      margin-bottom: 0rem;
    }
  }

  &__button {
    width: $login-width;
  }
  &__form {
    width: $login-width;
  }

  &__google-button {
    font-size: 1.6rem;
    font-family: Roboto-Medium;
    background-color: #4285f4;
    color: white;
    height: 4.4rem;
    border: 0px solid black;
    cursor: pointer;
    width: $login-width;
    padding-left: 0.7rem;
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;

    img {
      height: 4.5rem;
      padding: 0.5rem 0rem;
      padding-right: 1.8rem;
    }

    p {
      margin: 0px;
    }
  }

  &__horizontal-border {
    width: $login-width;
    position: relative;
    font: 400 1.4rem "Open Sans", sans-serif;
    color: #474648;
    border-bottom: 0.1rem solid #c5c4c6;
    margin-top: 3rem;

    span {
      position: absolute;
      top: -10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      background: #f7f6f8;
      padding: 0 10px;
    }
  }
  &__start-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-device-width: 480px) {
      font-size: 1.2rem;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      margin: 1rem 2rem;
    }
  }

  &__title {
    font-size: 2.4rem;
  }
  &__options {
    text-align: center;
    border: 1px solid lightgray; 
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: white;
  }
}
