.sign-out-button {
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  &__text {
    font-size: 1.4rem;
    position: relative;
    top: 5px;
    color: black;
  }
}
