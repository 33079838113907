.sign-in {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border: 2px solid black;
  box-shadow: 0 0 3px 0px black;
  padding: 2rem;
  margin: 5rem 0rem;
  position: relative;

  @media screen and (max-device-width: 480px) {
    padding: 1rem;
    font-size: 1.4rem;
    position: relative;
    margin: 5rem 0rem;
  }

  &__title {
    text-align: center;
    padding: 1vw;
    color: orange;
    background-color: linen;
    border: 2px solid black;
  }

  &__back-button {
    border: 1px solid #ff8914;
    border-radius: 1px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vw;
    color: #ff8914;
    cursor: pointer;
    margin-top: 1.5vw;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      opacity: 0.7;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0rem;
  }

  &__description {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 2rem;
  }
  &__info {
    display: flex;
    justify-content: center;
    margin-top: 1.5vw;
  }
  h3 {
    text-align: center;
    font-weight: normal;
  }
  li {
    text-align: left;
    font-weight: normal;
    list-style-type: none;
    margin: 2rem 0rem;
  }

  &__li-note {
    display: flex;
    margin: auto;
    justify-content: center;
  }
  &__note {
    text-align: center;
    font-weight: normal;
  }
}
