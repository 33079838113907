$color-primary: #ff9d00;
$color-primary-light: #ffe0b2;
$color-primary-background: #1f1f1f;
$color-primary-background-light: #f5f5f5;
$color-primary-box-background: white;
$color-primary-text: #1f1f1f;
$color-secondary: #0d58a6;
$color-secondary-light: #b3e5ff;
$color-highlight-game: #1ff333;
$color-highlight-game-bg: #1f56f3;

//text colors
$color-text-main: #333;

$color-green: #4caf50;
$color-red: #f44336;
$color-gray: #616161;
//game-matchup colors

$color-game-selected: #bbdefb;
$color-game-selected-live: #d7ccc8;
$color-game-selected-final-win: #c8e6c9;
$color-game-selected-final-loss: #ffcdd2;
$color-game-nonselected: #fff;
$color-game-nonselected-live: #d7ccc8;
$color-game-nonselected-final: #cfd8dc;

//font
$font-primary: "Roboto";
